import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import PlainMessage from './PlainMessage';
import styles from './email_message.module.scss';

const EmailMessage = ({ message, metadeta }) => {
  const { email_subject } = metadeta || {};
  const senderName = 'Lucet Notifications';
  const senderEmail = 'tridiuum1notifications@tridiuum.com';
  const receiverEmail = 'recipient@example.com';
  const dateReceived = moment().format('MMMM D, YYYY h:mm A');

  return (
    <div className={clsx(styles.emailViewContainer)}>
      {email_subject && (
        <div className={clsx(styles.emailHeader)}>
          <h2 className={clsx(styles.emailSubject)}>{email_subject}</h2>
        </div>
      )}

      <div className={clsx(styles.emailDetails)}>
        <p className={clsx(styles.mb10)}>
          <strong>From:</strong> {senderName} &lt;{senderEmail}&gt;
        </p>
        <p className={clsx(styles.mb10)}>
          <strong>To:</strong> {receiverEmail}
        </p>
        <p className={clsx(styles.mb10)}>
          <strong>Date:</strong> {dateReceived}
        </p>
      </div>

      <div className={clsx(styles.emailBody)}>
        <PlainMessage message={message} />
      </div>
    </div>
  );
};

export default EmailMessage;
