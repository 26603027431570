import React from 'react';
import clsx from 'clsx';

import styles from './base_email.module.scss';

const Email = ({ mode, children, bodyClasses = '' }) => {
  const isLive = mode === 'live'; // mode options case be view, edit or live

  return (
    <div
      className={clsx(styles.baseEmailWrap, isLive ? 'is-live' : 'is-preview')}
      tabIndex="-1"
    >
      <div
        className={clsx('email-content', 'w-100', bodyClasses)}
        aria-live="polite"
      >
        {children}
      </div>
    </div>
  );
};

export default Email;
